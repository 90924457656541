import { QueryClient } from "@tanstack/react-query"
import storage from "services/Storage"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
})

export const globalState = {
  language: "en",
  user: storage.get("user"),
  isAuth:
    !!new URL(window.location).searchParams.get("token") ||
    !!sessionStorage.getItem("token") ||
    !!storage.get("token"),
}

export const freeDeliveryStartsFrom = 30000

export const defaultMeta = {
  currentPage: 1,
  pageCount: 1,
  perPage: 1,
  totalCount: 1,
}
