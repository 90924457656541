import { Icons } from "components"

import avatar from "assets/images/png/avatar.png"

import "./mobile-menu.scss"
import { NavLink } from "react-router-dom"
import { navbar } from "Layout/Navbar"
import { helpers } from "services"

function MobileMenu() {
  const closeMenu = () => {
    const menu = document.querySelector(".mobile-menu")
    if (menu) {
      menu.classList.remove("menu-open")
    }
  }

  return (
    <div className="mobile-menu">
      <div className="mobile-menu__overlay" onClick={closeMenu}>
        {" "}
      </div>
      <div className="mobile-menu__wrapper">
        <div className="mobile-menu__header">
          <div className="avatar">
            <img src={avatar} alt="" />
          </div>
          <div className="mobile-menu__header-content">
            <h3 className="mobile-menu__header-title">100k CMS</h3>
            <p className="mobile-menu__header-text">
              admin
              <span>
                <Icons.ArrowRightLong />
              </span>
            </p>
          </div>
        </div>
        <div className="mobile-menu__items">
          {navbar.map(({ permission, to, label, icon }) =>
            helpers.checkPermissions(permission) ? (
              <NavLink to={to} className="mobile-menu__item" key={to} onClick={closeMenu}>
                {icon}
                <p className="mobile-menu__item-label">{label}</p>
              </NavLink>
            ) : null,
          )}
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
