import { NavLink, useHref, useSearchParams } from "react-router-dom"
import { helpers } from "services"

import {
  dashboard,
  order,
  profile,
  settings,
  store,
  user,
  ticket,
  logistics,
  marketing,
} from "./collections"
import "./sidebar.scss"

function Sidebar() {
  const [searchParams] = useSearchParams()
  const href = useHref()

  let activeNav = helpers.filterNav(dashboard)

  if (href.includes("/user") || activeNav.length === 0) activeNav = helpers.filterNav(user)
  if (href.includes("/order") || activeNav.length === 0) activeNav = helpers.filterNav(order)
  if (href.includes("/ticket") || activeNav.length === 0) activeNav = ticket
  if (href.includes("/store") || activeNav.length === 0) activeNav = helpers.filterNav(store)
  if (href.includes("/logistics") || activeNav.length === 0)
    activeNav = helpers.filterNav(logistics)
  if (href.includes("/marketing") || activeNav.length === 0)
    activeNav = helpers.filterNav(marketing)
  if (href.includes("/settings") || activeNav.length === 0) activeNav = settings
  if (href.includes("/profile") || activeNav.length === 0) activeNav = profile

  if (href === "/") return ""
  return (
    <aside className="sidebar">
      <ul className="sidebar__items">
        {activeNav.map(({ to, label, icon, end, onClick = () => {}, preventSearchParams }) => {
          searchParams.set("page", 1)

          return (
            <li
              key={to}
              onClick={() => {
                onClick({ to, from: window.location.pathname })
              }}
            >
              <NavLink
                to={preventSearchParams ? `${to}?${searchParams.toString()}` : to}
                className="sidebar__item"
                end={end}
              >
                <span className="icon">{icon}</span>
                <p className="sidebar__item-text">{label}</p>
              </NavLink>
            </li>
          )
        })}
      </ul>
    </aside>
  )
}

export default Sidebar
