import { createRoot } from "react-dom/client"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { QueryClientProvider } from "@tanstack/react-query"
import { Toaster } from "react-hot-toast"
import { I18nextProvider } from "react-i18next"
import { i18n } from "services"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import Routes from "Routes/index"
import { queryClient } from "config"

import "react-tippy/dist/tippy.css"
import "assets/styles/index.scss"

Sentry.init({
  dsn: process.env.REACT_APP_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
})

const root = createRoot(document.getElementById("root"))

root.render(
  <>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <Routes />
      </I18nextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
    <Toaster position="top-right" />
  </>,
)
