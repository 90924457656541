import { useState } from "react"
import toast from "react-hot-toast"
import storage from "services/Storage"

function useCall() {
  const [isFetching, setIsFetching] = useState(false)

  const callToClient = ({ orderId }) => {
    if (!isFetching) {
      setIsFetching(true)
      const call = fetch(`${process.env.REACT_APP_API_BASE_URL}orders/${orderId}/make-call`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${storage.get("token")}`,
        },
      })
        .then(() => {
          setIsFetching(false)
        })
        .catch(() => {
          setIsFetching(false)
        })

      toast.promise(call, {
        loading: "So'rov yuborilmoqda",
        success: "So'rov yuborildi",
        error: "So'rov yuborishda xatolik",
      })
    }
  }

  return { callToClient, isFetching }
}

export default useCall
