import { lazy } from "react"
import { helpers } from "services"
import Select from "./Select"

const Map = helpers.withSuspense(lazy(() => import("./Map")))
const Time = helpers.withSuspense(lazy(() => import("./Time")))
const Badge = helpers.withSuspense(lazy(() => import("./Badge")))
const Input = helpers.withSuspense(lazy(() => import("./Input")))
const Color = helpers.withSuspense(lazy(() => import("./Color")))
const Switch = helpers.withSuspense(lazy(() => import("./Switch")))
const Radios = helpers.withSuspense(lazy(() => import("./Radios")))
const Editor = helpers.withSuspense(lazy(() => import("./Editor")))
const Upload = helpers.withSuspense(lazy(() => import("./Upload")))
const Rating = helpers.withSuspense(lazy(() => import("./Rating")))
const Checkbox = helpers.withSuspense(lazy(() => import("./Checkbox")))
const Textarea = helpers.withSuspense(lazy(() => import("./Textarea")))
const InputMask = helpers.withSuspense(lazy(() => import("./InputMask")))
const Datepicker = helpers.withSuspense(lazy(() => import("./Datepicker")))
const Permissions = helpers.withSuspense(lazy(() => import("./Permissions")))
const AsyncSelect = helpers.withSuspense(lazy(() => import("./AsyncSelect")))
const RangePicker = helpers.withSuspense(lazy(() => import("./RangePicker")))
const SelectModal = helpers.withSuspense(lazy(() => import("./SelectModal")))
const RadioButtons = helpers.withSuspense(lazy(() => import("./RadioButtons")))
const SelectTooltip = helpers.withSuspense(lazy(() => import("./SelectTooltip")))
const SelectCreatable = helpers.withSuspense(lazy(() => import("./SelectCreatable")))

export default {
  Map,
  Time,
  Badge,
  Color,
  Input,
  InputMask,
  Select,
  Switch,
  Upload,
  Rating,
  Editor,
  Radios,
  Textarea,
  Checkbox,
  Datepicker,
  Permissions,
  AsyncSelect,
  RangePicker,
  SelectModal,
  RadioButtons,
  SelectTooltip,
  SelectCreatable,
}
