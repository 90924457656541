import { ErrorBoundary } from "components"
import Layout from "Layout"
import { Navigate } from "react-router-dom"

import Pages from "./Pages"

const AdminRoutes = [
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        permission: "profile",
        index: true,
        element: <Pages.Statistics />,
      },
      {
        permission: "product_list",
        path: "/product",
        element: <Pages.ProductList />,
      },
      {
        permission: "product_preview",
        path: "/product/update/:id",
        element: <Pages.ProductUpdate />,
      },

      {
        permission: "product_post_list",
        path: "/video-post",
        element: <Pages.VideoPostList />,
      },
      {
        permission: "product_post_create",
        path: "/video-post/create",
        element: <Pages.VideoPostCreate />,
      },
      {
        permission: "product_post_preview",
        path: "/video-post/update/:id",
        element: <Pages.VideoPostUpdate />,
      },

      {
        permission: "brand_list",
        path: "/brand",
        element: <Pages.BrandList />,
      },
      {
        permission: "brand_create",
        path: "/brand/create",
        element: <Pages.BrandCreate />,
      },
      {
        permission: "brand_view",
        path: "/brand/update/:id",
        element: <Pages.BrandUpdate />,
      },

      {
        permission: "category_list",
        path: "/category",
        element: <Pages.CategoryList />,
      },
      {
        permission: "category_create",
        path: "/category/create",
        element: <Pages.CategoryCreate />,
      },
      {
        permission: "category_preview",
        path: "/category/update/:id",
        element: <Pages.CategoryUpdate />,
      },
      {
        permission: "category_sort",
        path: "/category/sort",
        element: <Pages.CategorySort />,
      },

      {
        permission: "withdraw_list",
        path: "/withdraw",
        element: <Pages.WithdrawList />,
      },
      {
        permission: "withdraw_preview",
        path: "/withdraw/:id",
        element: <Pages.WithdrawView />,
      },

      {
        permission: "accounting_list",
        path: "/accounting",
        element: <Pages.AccountingList />,
      },
      {
        permission: "accounting_create",
        path: "/accounting/create",
        element: <Pages.AccountingCreate />,
      },
      {
        permission: "accounting_preview",
        path: "/accounting/update/:id",
        element: <Pages.AccountingUpdate />,
      },

      {
        permission: "stream_list",
        path: "/stream",
        element: <Pages.StreamList />,
      },
      {
        permission: "stream_preview",
        path: "/stream/update/:id",
        element: <Pages.StreamUpdate />,
      },

      {
        permission: "account_list",
        path: "/accounts",
        element: <Pages.AccountList />,
      },

      {
        permission: "review_list",
        path: "/review",
        element: <Pages.ReviewList />,
      },
      {
        permission: "review_create",
        path: "/review/create",
        element: <Pages.ReviewCreate />,
      },
      {
        permission: "review_preview",
        path: "/review/update/:id",
        element: <Pages.ReviewUpdate />,
      },

      {
        permission: "profile",
        path: "/profile",
        element: <Pages.Profile />,
      },
      {
        permission: "profile",
        path: "/profile/penalty/:id",
        element: <Pages.ProfilePenaltyReply />,
      },

      {
        permission: "order_list",
        path: "/order/:status",
        element: <Pages.OrderList />,
      },
      {
        permission: "order_create",
        path: "/order/create",
        element: <Pages.OrderCreate />,
      },
      {
        permission: "order_preview",
        path: "/order/update/:id",
        element: <Pages.OrderUpdate />,
      },
      {
        permission: "order_create",
        path: "/order/refund",
        element: <Pages.RefundList />,
      },
      {
        permission: "order_create",
        path: "/order/refund/:id",
        element: <Pages.RefundView />,
      },

      {
        permission: "location_list",
        path: "/location",
        element: <Pages.LocationList />,
      },
      {
        permission: "location_create",
        path: "/location/create",
        element: <Pages.LocationCreate />,
      },
      {
        permission: "location_preview",
        path: "/location/update/:id",
        element: <Pages.LocationUpdate />,
      },

      {
        permission: "ticket_list",
        path: "/ticket",
        element: <Pages.TicketList />,
      },
      {
        permission: "ticket_list",
        path: "/ticket-label",
        element: <Pages.TicketLabelList />,
      },
      {
        permission: "ticket_list",
        path: "/ticket-category",
        element: <Pages.TicketCategoryList />,
      },
      {
        permission: "ticket_get_one",
        path: "/ticket/update/:id",
        element: <Pages.TicketUpdate />,
      },

      {
        permission: "penalty_list",
        path: "/penalty",
        element: <Pages.PenaltyList />,
      },
      {
        permission: "penalty_preview",
        path: "/penalty/:id",
        element: <Pages.PenaltyView />,
      },

      {
        permission: "bot_user_list",
        path: "/bot-users",
        element: <Pages.BotUsers />,
      },

      {
        permission: "transaction_list",
        path: "/transaction/:id",
        element: <Pages.AccountTransactionList />,
      },
    ],
  },
  {
    path: "user",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        permission: "user_list",
        index: true,
        element: <Pages.UserList />,
      },
      {
        permission: "user_create",
        path: "/user/users/create",
        element: <Pages.UserCreate />,
      },
      {
        permission: "user_preview",
        path: "/user/users/update/:id",
        element: <Pages.UserUpdate />,
      },

      {
        permission: "user_group_list",
        path: "/user/group",
        element: <Pages.GroupList />,
      },
      {
        permission: "user_group_create",
        path: "/user/group/create",
        element: <Pages.GroupCreate />,
      },
      {
        permission: "user_group_preview",
        path: "/user/group/update/:id",
        element: <Pages.GroupUpdate />,
      },

      {
        permission: "user_transactions",
        path: "/user/transaction",
        element: <Pages.TransactionList />,
      },
    ],
  },
  {
    path: "/store/",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        permission: "store_list",
        index: true,
        element: <Pages.StoreList />,
      },
      {
        permission: "store_create",
        path: "create",
        element: <Pages.StoreCreate />,
      },
      {
        permission: "store_preview",
        path: "update/:id",
        element: <Pages.StoreUpdate />,
      },

      {
        permission: "store_withdraw_list",
        path: "withdraw",
        element: <Pages.StoreWithdrawList />,
      },
      {
        permission: "store_withdraw_preview",
        path: "withdraw/:id",
        element: <Pages.StoreWithdrawView />,
      },

      {
        permission: "income_list",
        path: "income",
        element: <Pages.IncomeList />,
      },
      {
        permission: "income_create",
        path: "income/create",
        element: <Pages.IncomeCreate />,
      },
      {
        permission: "income_preview",
        path: "income/update/:id",
        element: <Pages.IncomeUpdate />,
      },

      {
        permission: "product_outgoing_list",
        path: "outgoing",
        element: <Pages.OutgoingList />,
      },
      {
        permission: "product_outgoing_create",
        path: "outgoing/create",
        element: <Pages.OutgoingCreate />,
      },

      {
        permission: "seller_sale_list",
        path: "sale",
        element: <Pages.SaleList />,
      },

      {
        permission: "revision_list",
        path: "revision",
        element: <Pages.RevisionList />,
      },
      {
        permission: "revision_create",
        path: "revision/create",
        element: <Pages.RevisionCreate />,
      },
      {
        permission: "revision_list",
        path: "revision/:id",
        element: <Pages.RevisionView />,
      },
    ],
  },
  {
    path: "/logistics/",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        permission: "logistic_storage_list",
        path: "",
        element: <Pages.StorageList />,
      },
      {
        permission: "logistic_storage_create",
        path: "storage/create",
        element: <Pages.StorageCreate />,
      },
      {
        permission: "logistic_storage_preview",
        path: "storage/update/:id",
        element: <Pages.StorageUpdate />,
      },

      {
        permission: "order_waybill_list",
        path: "waybills",
        element: <Pages.WaybillsList />,
      },
      {
        permission: "order_waybill_preview",
        path: "waybills/:id",
        element: <Pages.WaybillsUpdate />,
      },

      {
        permission: "hold_product_list",
        path: "hold-product",
        element: <Pages.StorageHoldProductList />,
      },

      {
        permission: "storage_sales_list",
        path: "storage-sales",
        element: <Pages.IncomeFromStorage />,
      },

      {
        permission: "warehouse_list",
        path: "warehouse",
        element: <Pages.WarehouseList />,
      },
      {
        permission: "warehouse_create",
        path: "warehouse/create",
        element: <Pages.WarehouseCreate />,
      },
      {
        permission: "warehouse_view",
        path: "warehouse/update/:id",
        element: <Pages.WarehouseUpdate />,
      },

      {
        permission: "pickup_list",
        path: "pickup",
        element: <Pages.PickupList />,
      },
      {
        permission: "pickup_create",
        path: "pickup/create",
        element: <Pages.PickupCreate />,
      },
      {
        permission: "pickup_preview",
        path: "pickup/update/:id",
        element: <Pages.PickupUpdate />,
      },
    ],
  },
  {
    path: "marketing",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        permission: "promotion_list",
        path: "",
        element: <Pages.PromotionList />,
      },
      {
        permission: "promotion_create",
        path: "promotion/create",
        element: <Pages.PromotionCreate />,
      },
      {
        permission: "promotion_preview",
        path: "promotion/update/:id",
        element: <Pages.PromotionUpdate />,
      },

      {
        permission: "contest_list",
        path: "contest",
        element: <Pages.ContestList />,
      },
      {
        permission: "contest_create",
        path: "contest/create",
        element: <Pages.ContestCreate />,
      },
      {
        permission: "contest_preview",
        path: "contest/update/:id",
        element: <Pages.ContestUpdate />,
      },

      {
        permission: "discount_list",
        path: "discount",
        element: <Pages.DiscountList />,
      },

      {
        permission: "news_list",
        path: "news",
        element: <Pages.NewsList />,
      },
      {
        permission: "news_create",
        path: "news/create",
        element: <Pages.NewsCreate />,
      },
      {
        permission: "news_preview",
        path: "news/update/:id",
        element: <Pages.NewsUpdate />,
      },

      {
        permission: "banner_list",
        path: "banner",
        element: <Pages.BannerList />,
      },
      {
        permission: "banner_create",
        path: "banner/create",
        element: <Pages.BannerCreate />,
      },
      {
        permission: "banner_preview",
        path: "banner/update/:id",
        element: <Pages.BannerUpdate />,
      },

      {
        permission: "coupon_list",
        path: "coupon",
        element: <Pages.CouponList />,
      },
      {
        permission: "coupon_create",
        path: "coupon/create",
        element: <Pages.CouponCreate />,
      },
      {
        permission: "coupon_preview",
        path: "coupon/update/:id",
        element: <Pages.CouponUpdate />,
      },

      {
        permission: "promo_code_list",
        path: "promocode",
        element: <Pages.PromocodeList />,
      },
      {
        permission: "promo_code_create",
        path: "promocode/create",
        element: <Pages.PromocodeCreate />,
      },
      {
        permission: "promo_code_preview",
        path: "promocode/update/:id",
        element: <Pages.PromocodeUpdate />,
      },
    ],
  },
  {
    path: "",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        permission: "admin_config_list",
        path: "/settings",
        element: <Pages.Settings />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />,
    children: [],
  },
]

export default AdminRoutes
