import api from "../api"

async function queryFn({ url, method = "get", route, urlSearchParams = {}, ttl = 0, data }) {
  const options = {
    url: `${route}/${url}`,
    method,
    params: urlSearchParams,
    cache: {
      ttl, // 60 minute.
    },
    data,
  }
  const response = await api(options)

  return response
}

export default queryFn
